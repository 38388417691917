import React from "react"
import { AFrameRenderer, Marker } from "react-web-ar"
// import Layout from "../components/layout"
// import earthImage from "../images/gatsby-astronaut.png"
import journeySummaryImage from "../images/sravs.png"
import Layout from "../components/layout"

class IndexPage extends React.Component {
  componentDidMount() {
    if (window && window.AFRAME) {
      const { AFRAME } = window
      AFRAME.registerComponent("ticket-listener", {
        init: function() {
          var lastIndex = -1
          var images = [
            "#userProfile",
            "#appTicket",
            "#appTicket",
            "#appTicket",
          ]
          window.addEventListener("click", function(event) {
            var box = document.getElementById("ticket")
            lastIndex = (lastIndex + 1) % images.length

            if (images[lastIndex] === "#userProfile") {
              document.querySelector("#ticket").emit("ticketAnimation")
            }

            box.setAttribute("material", `src: ${images[lastIndex]}`)
            box.setAttribute("visible", "src: true")
          })
        },
      })

      AFRAME.registerComponent("journey-details-listener", {
        init: function() {
          var lastIndex = -1
          var visible = ["false", "false", "true", "false"]
          window.addEventListener("click", function(event) {
            var box = document.getElementById("journey-details")
            lastIndex = (lastIndex + 1) % visible.length
            if (visible[lastIndex] === "true") {
              document
                .querySelector("#journey-details")
                .emit("journeyDetailsAnimation")
            }

            box.setAttribute("visible", `${visible[lastIndex]}`)
          })
        },
      })

      AFRAME.registerComponent("cylinder-info-icon-listener", {
        init: function() {
          var lastIndex = -1
          var position = [
            "-0.25 0.3 0.5",
            "-0.25 0.1 0.5",
            "-0.25 0.1 0.5",
            "-0.25 0.1 0.5",
          ]
          window.addEventListener("click", function(event) {
            lastIndex = (lastIndex + 1) % position.length
            var cylinderUserPhoto = document.getElementById(
              "cylinder-info-icon"
            )

            cylinderUserPhoto.setAttribute("position", `${position[lastIndex]}`)
          })
        },
      })

      AFRAME.registerComponent("cylinder-train-icon-listener", {
        init: function() {
          var lastIndex = -1
          var position = [
            "0.25 0.1 0.5",
            "0.25 0.3 0.5",
            "0.25 0.3 0.5",
            "0.25 0.1 0.5",
          ]
          window.addEventListener("click", function(event) {
            lastIndex = (lastIndex + 1) % position.length
            var cylinderUserPhoto = document.getElementById(
              "cylinder-train-icon"
            )

            cylinderUserPhoto.setAttribute("position", `${position[lastIndex]}`)
          })
        },
      })
    }
  }

  render() {
    // return (
    //   // <Layout>
    //   <Marker
    //     parameters={{
    //       preset: "pattern",
    //       type: "pattern",
    //       url:
    //         // "https://raw.githubusercontent.com/lbelfield/augmented-reality/master/src/components/trainTicket/train-ticket.patt"
    //         "https://raw.githubusercontent.com/rksquared/sravs-test/master/src/components/trainTicket/pattern-sravs.patt",
    //     }}
    //   >
    //     <a-assets-item img id="journeySummaryImage" src={journeySummaryImage} />

    //     <a-entity
    //       geometry="primitive: box; height: 1;"
    //       material="shader: flat; src: #journeySummaryImage"
    //     />
    //   </Marker>
    //   // </Layout>
    // )
    return "test"
  }
}

export default IndexPage

// // import {Entity, Scene} from 'aframe-react'
// import React from "react"

// class IndexPage extends React.Component {
//   state = {
//     testCount: [1],
//     color: "red",
//   }

//   onClick1 = () => {
//     const testCount = [...this.state.testCount]
//     testCount.push(1)
//     console.log("in push click handler")
//     this.setState({ testCount })
//   }

//   changeColor() {
//     console.log("in click handler")
//     const colors = ["red", "orange", "yellow", "green", "blue"]
//     this.setState({
//       color: colors[Math.floor(Math.random() * colors.length)],
//     })
//   }

//   render() {
//     return (
//       <a-scene embedded arjs="sourceType: webcam;">
//         <a-box position="0 0.5 0" material="opacity: 0.5;"></a-box>
//         <a-marker-camera preset="hiro"></a-marker-camera>
//       </a-scene>
//     )
//   }
// }

// export default IndexPage

{
  /* <Scene embedded arjs="sourceType: webcam;"> */
}
{
  /* <a-assets>
    <img id="groundTexture" src="https://cdn.aframe.io/a-painter/images/floor.jpg" />
    <img id="skyTexture" src="https://cdn.aframe.io/a-painter/images/sky.jpg" />
  </a-assets> */
}

{
  /* <Entity primitive="a-plane" src="#groundTexture" rotation="-90 0 0" height="100" width="100" />
  <Entity primitive="a-light" type="ambient" color="#445451" />
  <Entity primitive="a-light" type="point" intensity="2" position="2 4 4" />
  <Entity primitive="a-sky" height="2048" radius="30" src="#skyTexture" theta-length="90" width="2048" /> */
}
{
  /* <Entity text={{ value: 'Happy Birthday Babe <3', align: 'center', color: 'black' }} position={{ x: 0, y: 2, z: 4 }} />

  <Entity id="box"
    geometry={{ primitive: 'box' }}
    material={{ color: this.state.color, opacity: 0.6 }}
    animation__rotate={{ property: 'rotation', dur: 2000, loop: true, to: '360 360 360' }}
    animation__scale={{ property: 'scale', dir: 'alternate', dur: 100, loop: true, to: '1.1 1.1 1.1' }}
    position={{ x: 0, y: 1, z: 2 }}
    events={{ click: this.changeColor.bind(this) }}>
    <Entity animation__scale={{ property: 'scale', dir: 'alternate', dur: 100, loop: true, to: '2 2 2' }}
      geometry={{ primitive: 'box', depth: 0.2, height: 0.2, width: 0.2 }}
      material={{ color: '#24CAFF' }} />
  </Entity>
  <Entity id="box"
    geometry={{ primitive: 'box' }}
    material={{ color: this.state.color, opacity: 0.6 }}
    animation__rotate={{ property: 'rotation', dur: 2000, loop: true, to: '360 360 360' }}
    animation__scale={{ property: 'scale', dir: 'alternate', dur: 100, loop: true, to: '1.1 1.1 1.1' }}
    position={{ x: 2, y: 3, z: -2 }}
    events={{ click: this.onClick1.bind(this) }}>
    {this.state.testCount.map((v, idx) => (
      <Entity animation__scale={{ property: 'scale', dir: 'alternate', dur: 100, loop: true, to: '2 2 2' }}
        geometry={{ primitive: 'box', depth: 0.2, height: 0.2 + v, width: 0.2 + v }}
        material={{ color: '#24CAFF' }} />))}
  </Entity>

  <Entity camera></Entity> */
}
{
  /* 
  <a-sphere position="0 0.5 0" radius="0.5" color="#EF2D5E"></a-sphere>
  <a-plane position="0 0 0" rotation="-90 0 0" width="1" height="1" color="#7BC8A4"></a-plane>

  <a-marker-camera preset='hiro'></a-marker-camera>
</Scene> */
}
{
  /* <Link to="/page-2/">Go to page 2</Link> */
}
